<template>
  <b-card
    title="Edit account"
  >

    <validation-observer ref="simpleRules">
      <b-form
        v-if="account._id"
        @submit.prevent
      >

        <b-row>

          <b-col cols="6">
            <b-form-group
              label="Name(Label)"
              label-for="v-name"
            >

              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >

                <b-form-input
                  id="v-name"
                  v-model="account.name"
                  placeholder="Name"
                  required
                  :state="errors.length > 0 ? false:null"
                />
              </validation-provider>

            </b-form-group>
          </b-col>

          <b-col cols="6">

            <b-form-group
              label="Seller Id(merchant token)"
              label-for="v-sellerId"
            >
              <b-form-input
                id="v-sellerId"
                v-model="account.sellerId"
                placeholder="sellerId"
                required
                readonly
              />
            </b-form-group>

          </b-col>

          <b-col cols="6">

            <b-form-group
              label="BigQuery DataSet"
              label-for="v-bigQueryDataSet"
            >
              <b-form-input
                id="v-bigQueryDataSet"
                v-model="account.bigQueryDataSet"
                placeholder="BigQuery DataSet"
                required
              />
            </b-form-group>

          </b-col>

          <b-col cols="6">

            <b-form-group
              label="BigQuery Customers Table"
              label-for="v-bigQueryCustomersTable"
            >
              <b-form-input
                id="v-bigQueryCustomersTable"
                v-model="account.bigQueryCustomersTable"
                placeholder="BigQuery Customers Table"
                required
              />
            </b-form-group>

          </b-col>

          <b-col cols="6">

            <b-form-group
              label="BigQuery OrderItems Table"
              label-for="v-bigQueryOrderItemsTable"
            >
              <b-form-input
                id="v-bigQueryOrderItemsTable"
                v-model="account.bigQueryOrderItemsTable"
                placeholder="BigQuery OrderItems Table"
                required
              />
            </b-form-group>

          </b-col>

          <b-col cols="6">

            <b-form-group
              label="BigQuery Report Returns Table"
              label-for="v-bigQueryReportReturnsTable"
            >
              <b-form-input
                id="v-bigQueryReportReturnsTable"
                v-model="account.bigQueryReportReturnsTable"
                placeholder="BigQuery Report Returns Table"
                required
              />
            </b-form-group>

          </b-col>

          <b-col cols="6">

            <b-form-group
              label="BigQuery Report Fba Inventory Daily Table"
              label-for="v-bigQueryReportsFbaInventoryDailyTable"
            >
              <b-form-input
                id="v-bigQueryReportsFbaInventoryDailyTable"
                v-model="account.bigQueryReportsFbaInventoryDailyTable"
                placeholder="BigQuery Report Fba Inventory Daily Table"
                required
              />
            </b-form-group>

          </b-col>

          <b-col cols="6">

            <b-form-group
              label="BigQuery Fba Inventory Table"
              label-for="v-bigQueryFbaInventoryTable"
            >
              <b-form-input
                id="v-bigQueryFbaInventoryTable"
                v-model="account.bigQueryFbaInventoryTable"
                placeholder="BigQuery Fba Inventory Table"
                required
              />
            </b-form-group>

          </b-col>

          <b-col cols="6">

            <b-form-group
              label="Fba inventory Run at(UTC time)"
              label-for="v-inventoryRunAt"
            >
              <b-form-input
                id="v-inventoryRunAt"
                v-model="account.inventoryRunAt"
                placeholder="Fba inventory Run at"
                required
              />
            </b-form-group>

          </b-col>

          <b-col cols="6">

            <b-form-group
              label="Orders Run at(UTC time)"
              label-for="v-runAt"
            >
              <b-form-input
                id="v-runAt"
                v-model="account.runAt"
                placeholder="Run at"
                required
              />
            </b-form-group>

          </b-col>

          <b-col cols="6">

            <b-form-group
              label="Orders Start days (- days form today )"
              label-for="v-startAt"
            >
              <b-form-input
                id="v-startAt"
                v-model="account.startAt"
                placeholder="Start at"
                required
              />
            </b-form-group>

          </b-col>

          <b-col cols="6">

            <b-form-group
              label="Orders Number of days to fetch"
              label-for="v-days"
            >
              <b-form-input
                id="v-days"
                v-model="account.days"
                placeholder="Days"
                required
              />
            </b-form-group>

          </b-col>

          <b-col cols="6">

            <b-form-group
              label="BigQuery Prodcuts Rankings Table"
              label-for="v-bigQueryProdcutsRankingsTable"
            >

              <validation-provider
                #default="{ errors }"
                name="First Name"
                rules="required"
              >
                <b-form-input
                  id="v-bigQueryProdcutsRankingsTable"
                  v-model="account.bigQueryProdcutsRankingsTable"
                  placeholder="BigQuery Prodcuts Rankings Table"
                  :state="errors.length > 0 ? false:null"
                  required
                />
              </validation-provider>
            </b-form-group>

          </b-col>

          <b-col cols="6">

            <b-form-group
              label="BigQuery Prodcuts Traffic and Sales Children  Table"
              label-for="v-bigQueryReportTrafficChildrenTable"
            >
              <b-form-input
                id="v-bigQueryReportTrafficChildrenTable"
                v-model="account.bigQueryReportTrafficChildrenTable"
                placeholder="BigQuery Prodcuts Rankings Table"
                required
              />
            </b-form-group>

          </b-col>

          <b-col cols="6">

            <b-form-group
              label="BigQuery Prodcuts Traffic and Sales Parents Table"
              label-for="v-bigQueryReportTrafficParentsTable"
            >
              <b-form-input
                id="v-bigQueryReportTrafficParentsTable"
                v-model="account.bigQueryReportTrafficParentsTable"
                placeholder="BigQuery Prodcuts Rankings Table"
                required
              />
            </b-form-group>

          </b-col>

          <b-col cols="6">

            <b-form-group
              label="BigQuery Brand Analytics Market Basket Table"
              label-for="v-bigQueryBrandAnalyticsMarketBasketTable"
            >
              <b-form-input
                id="v-bigQueryBrandAnalyticsMarketBasketTable"
                v-model="account.bigQueryBrandAnalyticsMarketBasketTable"
                placeholder="BigQuery Brand Analytics Market Basket Table"
                required
              />
            </b-form-group>

          </b-col>

          <b-col cols="6">

            <b-form-group
              label="BigQuery Brand Analytics Repeat Purchase Table"
              label-for="v-bigQueryBrandAnalyticsRepeatPurchaseTable"
            >
              <b-form-input
                id="v-bigQueryBrandAnalyticsRepeatPurchaseTable"
                v-model="account.bigQueryBrandAnalyticsRepeatPurchaseTable"
                placeholder="BigQuery Brand Analytics Repeat Purchase Table"
                required
              />
            </b-form-group>

          </b-col>

          <b-col cols="6">

            <b-form-group
              label="BigQuery Ledger Summary View Data Report Table"
              label-for="v-bigQueryLedgerSummaryViewDataReportTable"
            >
              <b-form-input
                id="v-bigQueryLedgerSummaryViewDataReportTable"
                v-model="account.bigQueryLedgerSummaryViewDataReportTable"
                placeholder="BigQuery Ledger Summary View Data Report Table"
                required
              />
            </b-form-group>

          </b-col>

          <b-col cols="6">

            <b-form-group
              label="BigQuery Financial Events Shipments Table"
              label-for="v-bigQueryFinancialEventsShipmentsTable"
            >
              <b-form-input
                id="v-bigQueryReportReturnsTable"
                v-model="account.bigQueryFinancialEventsShipmentsTable"
                placeholder="BigQuery Financial Events Shipments Table"
                required
              />
            </b-form-group>

          </b-col>

          <b-col cols="6">

            <b-form-group
              label="Notifications Email "
              label-for="v-notificationEmail"
            >
              <b-form-input
                id="v-notificationEmail"
                v-model="account.notificationEmail"
                placeholder="Notification Email"
                type="email"
                required
              />
            </b-form-group>

          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Status"
              label-for="v-status"
              required
            >
              <b-form-select
                v-model="account.active"
                :options="statusOptions"
              />

            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col cols="12">

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="loading"
              @click="saveAccount"
            >
              Save
            </b-button>

            <router-link :to="{name:'amz-selling-partners'}">

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >

                Back
              </b-button>

            </router-link>

          </b-col>
        </b-row>

      </b-form>
    </validation-observer>

  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormSelect, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required,
  alpha_num,
  alpha_dash,
} from 'vee-validate/dist/rules'

extend('required', required)
extend('alpha_num', alpha_num)
extend('alpha_dash', alpha_dash)

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      account: {},
      loading: false,
      channelSelected: null,
      statusOptions: [
        { value: true, text: 'Active' },
        { value: false, text: 'Disabled' },
      ],
    }
  },
  mounted() {
    this.loadAccount(this.$route.params.accountId)
  },
  methods: {

    loadAccount(accountId) {
      this.$store.dispatch('amzSellingPartnerAccount/get', accountId)
        .then(result => {
          this.account = result.data
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while loading items', 'danger')
        })
    },

    saveAccount() {
      this.$refs.simpleRules.validate().then(valid => {
        if (valid) {
          const data = this.account
          this.$store.dispatch('amzSellingPartnerAccount/update', data)
            .then(() => {
              this.showToast('Success', 'Item was updated successfully', 'success')
            },
            error => {
              this.showToast('Error', error.response?.data?.message || error?.message || 'Error while updating item', 'danger')
            })
        } else {
          this.showToast('Form', 'Check your inputs please', 'warning')
        }
      }, error => {
        console.log(error)
      })
    },

    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          text,
          variant,
        },
      })
    },
  },
}
</script>
